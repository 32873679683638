<template>
  <a-menu v-model="currentMenu" mode="horizontal" theme="dark">
    <a-menu-item key="sql"><a href="/#/sql">SQL查询</a></a-menu-item>
    <a-menu-item key="ticket"><a href="/#/ticket">工单</a></a-menu-item>
    <a-menu-item key="system" v-if="userInfo.menuItems && userInfo.menuItems.length"><a :href="`/#${path}`">系统设置</a></a-menu-item>
  </a-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Navbar',
  data() {
    return {
      currentMenu: ['sql']
    };
  },
  created() {
    this.handlePath();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    }),
    path() {
      return this.userInfo.menuItems[0];
    }
  },
  methods: {
    handlePath() {
      const path = this.$route.path;
      if (path.indexOf('/sql') > -1) {
        this.currentMenu = ['sql'];
      } else if (path.indexOf('/ticket') > -1) {
        this.currentMenu = ['ticket'];
      } else if (path.indexOf('/system') > -1) {
        this.currentMenu = ['system'];
      } else {
        this.currentMenu = [];
      }
    }
  },
  watch: {
    $route() {
      this.handlePath();
    }
  }
};
</script>

<style scoped>

</style>
