<template>
  <div class="login">
    <header>
      <cc-logo-header/>
    </header>
    <div class="content">
      <div class="has-background">
        <div class="tabs">
          <a-tabs v-model="loginForm.accountType" @change="handleTabChange">
            <a-tab-pane :key="ACCOUNT_TYPE.PRIMARY_ACCOUNT" tab="账号密码登录">
            </a-tab-pane>
            <a-tab-pane :key="ACCOUNT_TYPE.SUB_ACCOUNT"
                        :tab="globalSettings.ldapEnable ? 'LDAP' : '子账号登录'"></a-tab-pane>
          </a-tabs>
          <div class="tabs-content">
            <div class="msg" v-if="errMsg">
              <a-alert :message="errMsg" banner type="error"/>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;"
                 v-if="loginForm.accountType === ACCOUNT_TYPE.PRIMARY_ACCOUNT">
              <div style="font-size: 14px;">
                {{ loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD ? '账号密码登陆' : '短信验证码登录' }}
              </div>
              <div style="cursor: pointer;display: flex;" @click="handleChangeLoginType">
                <div class="tooltip">{{
                    loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD ? '短信验证码登录' : '账号密码登录'
                  }}
                </div>
                <img v-if="loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD" :height="30" :width="30" alt=""
                     src="../assets/mes.png">
                <img v-else :height="30" :width="30" alt="" src="../assets/pass.png">
                <!--                <cc-iconfont :name="loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD ? 'login-pwd' : 'login-verify'"-->
                <!--                             :size="30"/>-->
              </div>
            </div>
            <div class="input-wrapper">
              <a-select v-model="loginForm.primaryUid" placeholder="选择组织" style="width: 360px;" size="large"
                        v-if="globalSettings.ldapEnable && loginForm.accountType === ACCOUNT_TYPE.SUB_ACCOUNT"
                        show-search
                        :filter-option="filterOption"
              >
                <a-select-option v-for="org in orgs" :value="org.uid" :key="org.uid">{{ org.company }}</a-select-option>
              </a-select>
              <div style="display: flex; align-items: center;">
                <a-input v-model="loginForm.account"
                         :placeholder="loginForm.accountType===ACCOUNT_TYPE.PRIMARY_ACCOUNT ? loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY ? '手机' : '手机/邮箱地址' :
                         globalSettings.ldapEnable ? 'LDAP账号' : '子账号'"
                         size="large"/>
                <cc-sms-button v-if="loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY"
                               :phone-number="loginForm.account" size="large" style="width: 120px;margin: 0 0 0 10px;"
                               verify-code-type="LOGIN" verify-type="SMS_VERIFY_CODE"/>
              </div>

              <a-input v-if="loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY" v-model="loginForm.verifyCode"
                       placeholder="验证码" size="large"/>
              <cc-password-input v-else v-model="loginForm.password"
                                 :handleEnter="handleEnter"
                                 size="large"/>
            </div>
            <a-button :disabled="loginLoading" :loading="loginLoading" size="large"
                      style="margin-top: 20px;width: 100%;"
                      type="primary"
                      @click="handleLogin">登录
            </a-button>
            <div class="operation">
              <div>没有账号，去<span @click="goRegister">注册</span></div>
              <div @click="goResetPassword">忘记密码</div>
            </div>
          </div>
          <a-modal v-model="showAccountInformationCompletionModal" title="账号信息补全" :mask-closable="false" :width="374">
            <a-form-model :model="completeForm" :rules="rules" :label-col="{span:4}" :wrapper-col="{span:16}" ref="completeModalRef">
              <a-form-model-item label="邮箱" prop="email">
                <a-input v-model="completeForm.email" style="width: 280px;"/>
              </a-form-model-item>
              <a-form-model-item label="手机号" prop="phone">
                <a-input v-model="completeForm.phone" style="width: 280px;"/>
              </a-form-model-item>
            </a-form-model>
            <div class="footer">
              <a-button type="primary" @click="handleComplete">保存</a-button>
              <a-button @click="hideCompleteModal">取消</a-button>
            </div>
          </a-modal>
        </div>
      </div>
    </div>
    <footer>
      <cc-footer/>
    </footer>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import CCFooter from '@components/CCFooter';
import CCLogoHeader from '@components/CCLogoHeader';
import { ACCOUNT_TYPE, LOGIN_TYPE } from '@/consts';
import { UPDATE_USERINFO } from '@/store/mutationTypes';
import Vue from 'vue';

export default {
  name: 'Login',
  components: {
    CcLogoHeader: CCLogoHeader,
    CcFooter: CCFooter
  },
  data() {
    const validateSubaccount = async (checkType, checkContent, callback) => {
      console.log(checkContent, checkType.toLowerCase(), this.preCompleteForm[checkType.toLowerCase()]);
      if (checkContent === this.preCompleteForm[checkType.toLowerCase()]) {
        callback();
      } else {
        const res = await this.$services.checkSupplement({
          data: {
            checkType,
            checkContent,
            primaryUid: this.loginedForm.primaryUid
          },
          modal: false
        });
        if (res.success) {
          callback();
        } else {
          callback(new Error(res.msg));
        }
      }
    };
    const validateSubaccountPhone = (rule, value, callback) => {
      validateSubaccount('PHONE', value, callback);
    };
    const validateSubaccountEmail = (rule, value, callback) => {
      validateSubaccount('EMAIL', value, callback);
    };
    return {
      showAccountInformationCompletionModal: false,
      ACCOUNT_TYPE,
      LOGIN_TYPE,
      loginedForm: {},
      loginForm: {
        accountType: ACCOUNT_TYPE.PRIMARY_ACCOUNT,
        loginType: LOGIN_TYPE.LOGIN_PASSWORD,
        primaryUid: undefined,
        account: '',
        password: '',
        verifyCode: ''
      },
      completeForm: {
        email: '',
        phone: ''
      },
      preCompleteForm: {},
      errMsg: '',
      loginLoading: false,
      globalSettings: {},
      orgs: [],
      rules: {
        email: [
          { required: true, trigger: 'blur', message: '邮箱不能为空' },
          { validator: validateSubaccountEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, trigger: 'blur', message: '手机号不能为空' },
          { validator: validateSubaccountPhone, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async getSupplement() {
      const res = await this.$services.loadSupplementInfo({ data: this.loginedForm });

      if (res.success) {
        this.completeForm = res.data;
        this.preCompleteForm = cloneDeep(res.data);
      }
    },
    async handleComplete() {
      this.$refs.completeModalRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$services.login({
            data: {
              ...this.loginedForm,
              registerInfo: this.completeForm,
              loginType: this.globalSettings.ldapEnable && this.loginForm.accountType === ACCOUNT_TYPE.SUB_ACCOUNT ? LOGIN_TYPE.LOGIN_LDAP : this.loginForm.loginType
            }
          });
          if (res.success) {
            await this.$router.push({ name: 'Home' });
            this.hideCompleteModal();
          }
        }
      });
    },
    hideCompleteModal() {
      this.showAccountInformationCompletionModal = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleTabChange() {
      this.errMsg = '';
      Vue.set(this.loginForm, 'loginType', LOGIN_TYPE.LOGIN_PASSWORD);
    },
    handleChangeLoginType() {
      const loginType = this.loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY ? LOGIN_TYPE.LOGIN_PASSWORD : LOGIN_TYPE.LOGIN_VERIFY;
      Vue.set(this.loginForm, 'loginType', loginType);
    },
    async handleLogin() {
      this.loginLoading = true;
      const data = {
        ...this.loginForm,
        loginType: this.globalSettings.ldapEnable && this.loginForm.accountType === ACCOUNT_TYPE.SUB_ACCOUNT ? LOGIN_TYPE.LOGIN_LDAP : this.loginForm.loginType
      };
      this.loginedForm = data;
      const res = await this.$services.login({
        data
      });
      if (res.success) {
        this.errMsg = '';
        await this.$store.commit(UPDATE_USERINFO, res.data);
        if (res.data.needMore) {
          this.showAccountInformationCompletionModal = true;
          this.completeForm = res.data.moreInfo;
          this.preCompleteForm = cloneDeep(res.data.moreInfo);
        } else {
          await this.$router.push({ name: 'Home' });
        }
      } else {
        this.errMsg = res.msg;
      }
      this.loginLoading = false;
    },
    goRegister() {
      this.$router.push({ name: 'Register' });
    },
    goResetPassword() {
      this.$router.push({ name: 'Reset' });
    },
    handleEnter(arg) {
      if (arg.keyCode === 13) {
        this.handleLogin();
      }
    },
    async getGlobalSettings() {
      const res = await this.$services.getGlobalSettings({ data: {} });

      if (res.success) {
        this.globalSettings = res.data;
      }
    },
    async listOrg() {
      const res = await this.$services.listOrg({ data: {} });

      if (res.success) {
        this.orgs = res.data;
      }
    }
  },
  created() {
    this.getGlobalSettings();
    this.listOrg();
  }
};
</script>

<style lang="less">
.login {
  header {
    .width-full();
  }

  .content {
    width: 100%;
    .width-full();
    position: absolute;
    top: 80px;
    //bottom: 56px;
    background-color: #C3D8E9;
    overflow: scroll;
    height: 545px;
    //padding: 0 105px;

    .has-background {
      background: url("../assets/loginBack.png") no-repeat 0 60px;
      background-size: 580px;
      //width: 100%;
      height: 100%;
      width: 1200px;
      margin: 0 auto;
    }

    .tabs {
      width: 520px;
      margin-top: 60px;
      background: white;
      float: right;

      .ant-tabs-bar {
        border-bottom: 2px solid #ECECEC;
      }

      .ant-tabs-nav {
        height: 64px;
        margin-left: 82px;

        .ant-tabs-tab {
          line-height: 48px;
          font-size: 16px;
          color: #333333;
        }

        .ant-tabs-ink-bar {
          background: #0BB9F8;
        }

        .ant-tabs-tab-active {
          font-weight: 400;
        }
      }

      .tabs-content {
        padding: 20px 80px 48px 80px;

        .msg {
          position: relative;
          bottom: 10px;
        }

        .input-wrapper {
          & > div {
            margin-bottom: 20px;
          }
        }

        .ant-btn {
          width: 360px;
          margin-top: 8px;
          margin-bottom: 20px;
          font-size: 16px;

          span {
            font-size: 16px;
          }
        }
      }

      .operation {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        //margin-top: 20px;

        div:last-child,
        div > span {
          cursor: pointer;
        }

        div, span {
          font-size: 14px;
        }

        span {
          color: @primary-color;
        }
      }
    }
  }

  .tooltip {
    position: relative;
    width: 105px;
    height: 30px;
    border: 1px solid #FFC441;
    color: #FF6E0D;
    background-color: #FFF7DB;
    margin-right: 12px;
    line-height: 30px;
    text-align: center;
  }

  .tooltip:before {
    content: '';
    display: block;
    position: absolute;
    right: -10px;
    top: 5px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #FFC441;
  }

  .tooltip:after {
    content: '';
    display: block;
    position: absolute;
    right: -9px;
    top: 5px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #FFF7DB;
  }

  footer {
    .width-full();
    //position: absolute;
    //bottom: 0;
    margin-top: 542px;
  }
}

</style>
