import { VERIFY_CODE_TYPE, VERIFY_TYPE } from '@/consts';
import { Modal } from 'ant-design-vue';

const EMPTY_OP_CONFIG = {
  opPassword: '',
  verifyCode: ''
};
const setOpPasswordMixin = {
  data() {
    return {
      opEyeVisible: false,
      opModalConfig: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      },
      opForm: { ...EMPTY_OP_CONFIG },
      opModalErrMsg: {
        opPassword: '',
        verifyCode: ''
      },
      opErrorText: {
        opPassword: '操作密码',
        verifyCode: '验证码'
      }
    };
  },
  methods: {
    handleBlurOpModalValidate(type) {
      if (!this.opForm[type]) {
        this.opModalErrMsg[type] = `${this.opErrorText[type]}不能为空`;
      } else {
        this.opModalErrMsg[type] = '';
      }
    },
    handleChangeOpEye() {
      this.opEyeVisible = !this.opEyeVisible;
    },
    setOpPasswordModal(edit = false) {
      return new Promise(((resolve, reject) => {
        const handleResetOpPwd = async () => {
          const res = await this.$services.resetOpPwd({ data: this.opForm });
          if (res.success) {
            this.$Message.success('更新操作密码成功');
            hideModal();
            resolve();
          }
          reject();
        };
        const hideModal = () => {
          this.opForm = { ...EMPTY_OP_CONFIG };
          this.opModalErrMsg = { ...EMPTY_OP_CONFIG };
          modal.destroy();
        };
        const modal = Modal.warning({
          title: edit ? '修改操作密码' : '请先设置您的操作密码！',
          class: 'set-op-password-modal',
          width: 414,
          content: () => (
            <div style={{ marginLeft: 0 }}>
              {edit ? null
                : <a-alert type="warning" show-icon message="操作密码用于数据库查询/变更及部分敏感功能操作；设置操作密码后才能开始作业。"
                           style={{ marginBottom: '20px' }}/>}
              <a-form-model label-col={this.opModalConfig.labelCol}
                            wrapper-col={this.opModalConfig.wrapperCol}>
                <a-form-model-item label="短信验证码">
                  <div style={{ display: 'flex' }}>
                    <a-input v-model={this.opForm.verifyCode} style={{ marginRight: '8px' }}
                             onBlur={() => this.handleBlurOpModalValidate('verifyCode')}/>
                    <cc-sms-button verifyType={VERIFY_TYPE.SMS}
                                   verifyCodeType={VERIFY_CODE_TYPE.RESET_OP_PASSWORD}
                                   login={true}/>
                  </div>
                  <div className='error-msg'>{this.opModalErrMsg.verifyCode}</div>
                </a-form-model-item>
                <a-form-model-item label="操作密码">
                  <a-input v-model={this.opForm.opPassword}
                           type={this.opEyeVisible ? 'text' : 'password'} placeholder="密码"
                           autocomplete="new-password"
                           onBlur={() => this.handleBlurOpModalValidate('opPassword')}
                           onPressEnter={handleResetOpPwd}
                  >
                    <a-icon slot="suffix" type={this.opEyeVisible ? 'eye' : 'eye-invisible'}
                            size="large"
                            theme="filled" onclick={this.handleChangeOpEye}/>
                  </a-input>
                  <div className='error-msg'>{this.opModalErrMsg.opPassword}</div>
                </a-form-model-item>
              </a-form-model>
              <div class="footer">
                <a-button type="primary" onClick={handleResetOpPwd}>保存</a-button>
                <a-button onClick={hideModal}>取消</a-button>
              </div>
            </div>
          )
        });
      }));
    }
  }
};

export default setOpPasswordMixin;
