import { Modal } from 'ant-design-vue';

const setApprovalProcessMixin = {
  data() {
    return {
      aksk: {
        ak: '',
        sk: '',
        agentId: ''
      },
      akskModalErrMsg: {
        ak: '',
        sk: '',
        agentId: ''
      },
      modal: null
    };
  },
  methods: {
    handleBlurApprovalProcessModalValidate(type) {
      if (!this.aksk[type]) {
        this.akskModalErrMsg[type] = `${type}不能为空`;
      } else {
        this.akskModalErrMsg[type] = '';
      }
    },
    async updateKey() {
      const data = {
        appKey: this.aksk.ak,
        secretKey: this.aksk.sk,
        approvalType: 'DINGDING',
        agentId: this.aksk.agentId
      };
      const res = await this.$services.updateApproKey({ data });
      if (res.success) {
        window.$bus.emit('getTemplateList');
      }
    },
    handleUpdateKey() {
      if (!this.aksk.agentId) {
        this.akskModalErrMsg.agentId = 'ak不能为空';
      }
      if (!this.aksk.ak) {
        this.akskModalErrMsg.ak = 'ak不能为空';
      }
      if (!this.aksk.sk) {
        this.akskModalErrMsg.sk = 'sk不能为空';
      }
      if (this.akskModalErrMsg.ak || this.akskModalErrMsg.sk || this.akskModalErrMsg.agentId) {
        return false;
      }
      this.updateKey();
      this.modal.destroy();
      this.emptyAkSkModalData();
    },
    emptyAkSkModalData() {
      this.aksk = {
        ak: '',
        sk: '',
        agentId: ''
      };
      this.akskModalErrMsg = {
        ak: '',
        sk: '',
        agentId: ''
      };
    },
    setApprovalProcessModal() {
      this.modal = Modal.info({
        title: '审批流程访问权限',
        width: 620,
        loading: true,
        class: 'dingding-aprrove-modal',
        closable: true,
        centered: true,
        content: () => (
          <div>
            <a-alert type="warning" show-icon
                     style={{ marginBottom: '20px' }}>
              <p slot="message">
                对接阿里云钉钉审批流需创建钉钉 [ 企业内部应用
                ]。CloudDM会使用该应用的AppKey和AppSecret用于发起OpenAPI调用来创建审批实例、查询审批状态等。
                该企业内部应用具有特定访问权限用于发起审批实例、发送通知等权限。具体内容请查看 <a
                href="https://doc.clouddm.clougence.com/docs/en/dingtalk_setting"
                target="_blank">对接钉钉审批</a>.
              </p>
            </a-alert>
            <a-form data={this.aksk} label-col={{ span: 4 }} wrapper-col={{ span: 20 }}>
              <a-form-item label="AgentId">
                <a-input v-model={this.aksk.agentId}
                         onBlur={() => this.handleBlurApprovalProcessModalValidate('agentId')}/>
                <div className='error-msg'>{this.akskModalErrMsg.agentId}</div>
              </a-form-item>
              <a-form-item label="AppKey">
                <a-input v-model={this.aksk.ak}
                         onBlur={() => this.handleBlurApprovalProcessModalValidate('ak')}/>
                <div className='error-msg'>{this.akskModalErrMsg.ak}</div>
              </a-form-item>
              <a-form-item label="AppSecret">
                <a-input v-model={this.aksk.sk}
                         onBlur={() => this.handleBlurApprovalProcessModalValidate('sk')}/>
                <div className='error-msg'>{this.akskModalErrMsg.sk}</div>
              </a-form-item>
            </a-form>
            <div class="footer">
              <a-button type="primary" onclick={() => this.handleUpdateKey()}>授权访问</a-button>
              <a-button onClick={() => hideModal()}>取消</a-button>
            </div>
          </div>
        )
      });
      const hideModal = () => {
        this.modal.destroy();
        this.emptyAkSkModalData();
      };
    }
  }
};

export default setApprovalProcessMixin;
