import { Modal } from 'ant-design-vue';

const enterOpPwdMixin = {
  data() {
    return {
      enterOpPwdModalForm: {
        opPwd: ''
      }
    };
  },
  methods: {
    showEnterOpPwdModal() {
      const verifyOpPwd = async () => {
        const res = await this.$services.verifyOpPwd({
          data: {
            opPassword: this.enterOpPwdModalForm.opPwd,
            msg: '操作密码验证成功'
          }
        });
        if (res.success) {
          this.enterOpPwdModalForm = { opPwd: '' };
          modal.destroy();
        }
      };
      const modal = Modal.confirm({
        title: '请输入操作密码',
        class: 'enter-op-pwd-modal',
        width: 414,
        content: () => (
          <div class="enter-op-pwd-modal">
            <a-alert style="margin-bottom:20px" message="操作密码已失效，请重新输入！" type="warning" show-icon/>
            <div class="pwd">
              <div class="label">操作密码</div>
              <cc-password-input v-model={this.enterOpPwdModalForm.opPwd} handleEnter={verifyOpPwd}/>
            </div>
            <a-button class="reset" onClick={() => window.$bus.emit('setOpPasswordModal', true)} type="link">忘记密码</a-button>
            <div class="footer">
              <a-button type='primary' onClick={verifyOpPwd}>确定</a-button>
              <a-button onClick={() => modal.destroy()}>取消</a-button>
            </div>
          </div>
        )
      });
    }
  }
};

export default enterOpPwdMixin;
