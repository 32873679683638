<template>
  <div class="home">
    <c-c-water-mark :input-text="watermarkStr" ref="watermark"/>
    <div class="header">
      <div class="brand-container" @click="handleGoBackHome">
        <cc-iconfont :size="18" color="#FEFEFE" name="dm"></cc-iconfont>
      </div>
      <navbar style="flex:1"/>
      <div class="user-info">
<!--        <span @click="goAsyncJobList" style="cursor: pointer">导出任务</span>-->
        <cc-iconfont :size="20" name="help" @click.native="handleGoHelp"/>
        <a-dropdown v-if="userInfo.menuItems&&userInfo.menuItems.includes('/system/console_job')" :trigger="['click']">
        <span class="message-icon">
          <cc-iconfont :size="20" name="message"/>
          <span v-if="messageList.length>0" class="message-point"></span>
        </span>
          <div slot="overlay" class="message-list-container">
            <p class="title">消息中心</p>
            <div v-for="message in messageList" :key="message.id" class="message-item"
                 @click="handleGoMessageDetail(message.id)">
              <p>
                {{ CONSOLE_JOB_NAME[message.label] }}失败
              </p>
              <p class="time">{{ message.gmtModified | formatTime('YYYY-MM-DD HH:mm:ss') }}</p>
            </div>
            <div class="message-footer" @click="handleGoMessage">
              查看更多
            </div>
          </div>
        </a-dropdown>
        <div class="domain">{{ userInfo.username }}</div>
        <div v-click-out-hide="hideMenu" class="avatar" @click="showMenu=!showMenu">
          <img alt="userAvatar" src="../assets/head.png">
        </div>
        <div v-show="showMenu" class="menu">
          <div class="one">
            <div class="avatar">
              <img alt="userAvatar" src="../assets/head.png">
            </div>
            <div class="domain">
              {{ userInfo.username }}
              <a-tag color="orange" class="parent-account-label" v-if="!userInfo.parentId">
                主
              </a-tag>
            </div>
            <span v-if="userInfo.parentId">
              <a-popover v-model="showAccount" placement="left" trigger="hover">
              <p slot="content">
                {{ userInfo.subAccount }}
              </p>
              <cc-iconfont style="margin-left: 10px;margin-right: 6px" name="account" color="#555555"
                           :size="20"></cc-iconfont>
            </a-popover>
            <a @click="handleCopy(userInfo.subAccount)">复制账号</a>
            </span>
          </div>
          <div class="two">
            <div>电话: {{ userInfo.phone }}</div>
            <div>邮箱: {{ userInfo.email }}</div>
            <div v-if="userInfo.parentId">所属主账号: {{ userInfo.parentUserName }}</div>
          </div>
          <div class="three">
            <div @click="goReset" v-if="userInfo.bindType !== 'LDAP'">
              <cc-iconfont :size="12" color="#555555" name="password2"></cc-iconfont>
              修改登录密码
            </div>
            <div @click="_setOpPasswordModal" v-if="userInfo.global && userInfo.global.oppassword">
              <cc-iconfont :size="12" color="#555555" name="password"></cc-iconfont>
              修改操作密码
            </div>
            <div @click="goUserConfig" v-if="!userInfo.parentId">
              <a-icon style="font-size:12px;color:#555555" type="setting" />
              修改配置
            </div>
            <div @click="_setCloudAKSKModal" v-if="!userInfo.parentId">
              <cc-iconfont :size="12" color="#555555" name="fangwe"></cc-iconfont>
              阿里云访问权限
            </div>
            <div @click="_setApprovalProcessModal" v-if="!userInfo.parentId">
              <cc-iconfont :size="12" color="#555555" name="shenpi"></cc-iconfont>
              审批流程访问权限
            </div>
          </div>
          <div class="four" @click="logout">
            退出账号
          </div>
        </div>
      </div>
    </div>
    <router-view/>
    <a-modal v-model="showDetailModal" :title="selectedCellDetail.column.property" v-if="showDetailModal" key="showDetailModal" :width="800">
      <div v-if="selectedCellDetail.row[`render_${selectedCellDetail.column.property}`].moreSize">
        *内容过长已截断，如需查看完整内容，请提交数据导出工单或联系主账号
      </div>
      <div class="cell-detail">
        {{selectedCellDetail.row[selectedCellDetail.column.property]}}
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleDetailCopy">复制</a-button>
        <a-button @click="handleCloseCellDetailModal">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from '@components/Navbar';
import { setApprovalProcessMixin, setCloudAKSKMixin, setOpPasswordMixin } from '@/mixins/modal';
import enterOpPwdMixin from '@mixins/modal/enterOpPwdMixin';
import { CONSOLE_JOB_NAME } from '@/consts';
import XEClipboard from 'xe-clipboard';
import CCWaterMark from '../components/widgets/CCWaterMark.vue';
// import { UPDATE_USERINFO } from '../store/mutationTypes';

export default {
  name: 'Home',
  components: {
    CCWaterMark,
    Navbar
  },
  data() {
    return {
      showDetailModal: false,
      testHtml: 'test\ntest\n',
      selectedCellDetail: {
        column: {},
        row: {}
      },
      showMenu: false,
      showAccount: false,
      hasMessage: false,
      messageList: [],
      CONSOLE_JOB_NAME,
      watermarkStr: ''
    };
  },
  mixins: [setOpPasswordMixin, setCloudAKSKMixin, setApprovalProcessMixin, enterOpPwdMixin],
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  async created() {
    this.$store.dispatch('getUserInfo');
    this.$store.dispatch('getRegionList');
    const waterMark = await this.$services.watermark();
    this.watermarkStr = `${waterMark.data.user_name}_${waterMark.data.user_phone}`;
    window.$bus.on('setOpPasswordModal', (edit = false) => {
      if (this.userInfo && this.userInfo.global && this.userInfo.global.oppassword) {
        this.setOpPasswordModal(edit);
      }
    });
    window.$bus.on('showCellDetailModal', ({ row, column }) => {
      if (!this.showDetailModal) {
        this.showDetailModal = true;
        this.selectedCellDetail = {
          row,
          column
        };
      }
    });
    window.$bus.on('showEnterOpPwdModal', this.showEnterOpPwdModal);
    window.$bus.on('setCloudAKSKModal', this.setCloudAKSKModal);
    window.$bus.on('dingDingSettingModal', this.setApprovalProcessModal);
  },
  destroyed() {
    window.$bus.off('setOpPasswordModal');
    window.$bus.off('showEnterOpPwdModal');
    window.$bus.off('setCloudAKSKModal');
    window.$bus.off('dingDingSettingModal');
    window.$bus.off('showCellDetailModal');
  },
  methods: {
    handleDetailCopy() {
      const { row, column } = this.selectedCellDetail;
      if (XEClipboard.copy(row[column.property])) {
        this.$Message.success('复制成功');
      }
    },
    handleCloseCellDetailModal() {
      this.showDetailModal = false;
    },
    handleGoBackHome() {
      this.$router.push({ name: 'SQL' });
    },
    goAsyncJobList() {
      this.$router.push({ name: 'ASYNC_JOB_LIST' });
    },
    async listLastFiveFailedJob() {
      const res = await this.$services.listLastFiveFailedJob();

      if (res.success) {
        if (res.data.length > 0) {
          this.messageList = res.data;
        }
      }
    },
    _setCloudAKSKModal() {
      this.$store.dispatch('getUserInfo');
      this.setCloudAKSKModal();
    },
    _setApprovalProcessModal() {
      this.$store.dispatch('getUserInfo');
      this.setApprovalProcessModal();
    },
    _setOpPasswordModal() {
      this.$store.dispatch('getUserInfo');
      this.setOpPasswordModal(true);
    },
    goUserConfig() {
      this.$router.push({ name: 'User_Config' });
    },
    hideMenu() {
      this.showMenu = false;
    },
    async logout() {
      const res = await this.$services.logout();

      if (res.success) {
        // await this.$store.commit(UPDATE_USERINFO, {});
        // this.$refs.watermark.removeMaskDiv();
        await this.$router.push({ name: 'Login' });
      }
    },
    handleGoHelp() {
      window.open('https://doc.clouddm.clougence.com/docs/en/product_intro');
    },
    handleGoMessage() {
      this.$router.push({ path: '/system/info_center' }).catch(() => {});
    },
    handleGoMessageDetail(id) {
      this.$router.push({ path: `/system/console_job/${id}` });
    },
    goReset() {
      this.$router.push({ path: '/reset' });
    },
    handleCopy(data) {
      const input = document.createElement('textarea');

      input.value = data;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);

      this.$Message.success('复制成功！');
    }
  },
  watch: {
    userInfo(val) {
      if (val.showMessage) {
        this.listLastFiveFailedJob();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  .header {
    background: #0087c7;
    display: flex;
    justify-content: space-between;
    color: white;
    height: 48px;
    line-height: 48px;
    background-position: left;
    background-size: 200px 48px;
    background-repeat: no-repeat;

    .ant-menu-dark {
      background: none;
    }

    .brand-container {
      padding: 0 15px;
    }

    .ant-menu-horizontal {
      line-height: 48px;
    }

    .user-info {
      display: flex;
      align-items: center;
      position: relative;

      & > i {
        opacity: 0.7;
        cursor: pointer;
      }

      & > .domain {
        //opacity: 0.7;
      }

      & > *:not(:last-child) {
        margin-right: 20px;
        font-size: 14px;
      }

      .icon-help {
        font-size: 20px;
      }

      .avatar {
        cursor: pointer;
        width: 28px;
        height: 28px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .message-icon {
        position: relative;
        cursor: pointer;

        i {
          opacity: 0.7;
        }

        .message-point {
          position: absolute;
          background: #FF6E0D;
          width: 6px;
          height: 6px;
          display: block;
          border-radius: 50%;
          right: -2px;
          top: 6px;
        }
      }

      .menu {
        z-index: 999;
        position: absolute;
        width: 240px;
        top: 40px;
        right: 0;
        color: #333;
        box-shadow: 1px 1px 6px 0px rgba(164, 164, 164, 0.66);
        background: white;

        .avatar {
          width: 36px;
          height: 36px;
        }

        & > div:not(:last-child) {
          border-bottom: 1px solid rgba(223, 223, 223, 1);
        }

        .one {
          display: flex;
          align-items: center;
          padding: 20px;

          .domain {
            margin-left: 10px;
            font-size: 14px;
          }
          .parent-account-label {
            margin-left: 6px;
          }
        }

        .two {
          padding: 20px;
          line-height: 22px;

          div {
            margin-left: 0;
          }
        }

        .three {
          padding: 15px 24px;

          div {
            cursor: pointer;
            height: 22px;
            line-height: 22px;

            i {
              margin-right: 15px;
            }
          }
        }

        .four {
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #ececec;
          color: #FF6E0D;
          cursor: pointer;
        }
      }
    }
  }
}

.ivu-dropdown .ivu-select-dropdown {
  top: 80px !important;
}

.message-list-container {
  width: 360px;
  background: white;
  box-shadow: 1px 1px 6px rgba(164, 164, 164, 0.66);

  .title {
    background: #ECECEC;
    line-height: 50px;
    font-size: 14px;
    font-family: PingFangSC-Semibold;
    font-weight: 500;
    padding: 0 20px;
  }

  .time {
    color: #888888;
  }

  .message-item {
    padding: 20px;
    border-bottom: 1px solid #DFDFDF;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background: #F5F5F5;
    }
  }

  .message-footer {
    padding: 0 20px;
    line-height: 38px;
    cursor: pointer;

    &:hover {
      background: #F5F5F5;
    }
  }
}

.cell-detail {
  white-space: pre-line;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
}
</style>
