import { Modal } from 'ant-design-vue';

const setCloudAKSKMixin = {
  data() {
    return {
      akskModalConfig: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      },
      akskForm: {
        ak: '',
        sk: ''
      },
      akskFormRules: {
        ak: [
          {
            required: true,
            message: '请输入ak',
            trigger: 'blur'
          }
        ],
        sk: [
          {
            required: true,
            message: '请输入sk',
            trigger: 'blur'
          }
        ]
      },
      akskModalErrMsg: {
        ak: '',
        sk: ''
      }
    };
  },
  methods: {
    handleBlurCloudAkskModalValidate(type) {
      if (!this.akskForm[type]) {
        this.akskModalErrMsg[type] = `${type}不能为空`;
      } else {
        this.akskModalErrMsg[type] = '';
      }
    },
    setCloudAKSKModal() {
      const handleAuthorize = async () => {
        const {
          ak,
          sk
        } = this.akskForm;
        if (this.akskModalConfig.ak) {
          this.akskModalErrMsg.ak = 'ak不能为空';
        }
        if (this.akskModalConfig.sk) {
          this.akskModalErrMsg.sk = 'sk不能为空';
        }
        if (this.akskModalErrMsg.ak || this.akskModalErrMsg.sk) {
          return false;
        }

        const res = await this.$services.updatealiyunaksk({
          data: {
            aliyunAk: ak,
            aliyunSk: sk
          },
          msg: '授权成功'
        });

        if (res.success) {
          hideModal();
        }
      };
      const handleRemoveAuthorize = async () => {
        const res = await this.$services.cleanaliyunaksk({
          data: {},
          msg: '解除授权成功'
        });

        if (res.success) {
          this.$store.dispatch('getUserInfo');
          modal.destroy();
        }
      };
      const modal = Modal.info({
        title: `阿里云访问权限(${this.userInfo.aliyunAkSkSet ? '已设置' : '未设置'})`,
        width: 620,
        class: 'cloud-aksk-modal',
        closable: true,
        centered: true,
        content: () => (
          <div>
            <a-alert type="warning">
              <p slot="message">
                当使用 CloudDM 对阿里云云数据库、消息或者大数据产品进行数据操作和管理时，CloudDM 会使用用户提供的阿里云子账号的 AccessKey 和 SecretKey 进行必要的资源信息访问。
                <a href="https://doc.clouddm.clougence.com/docs/en/aliyun_ram_auth" target="_blank">如何申请阿里云访问权限？</a>
              </p>
            </a-alert>
            <a-form-model label-col={this.akskModalConfig.labelCol}
                          wrapper-col={this.akskModalConfig.wrapperCol}
                          style={{ marginTop: '20px' }}
            >
              <a-form-model-item label="AccessKey ID">
                <a-input v-model={this.akskForm.ak}
                         onBlur={() => this.handleBlurCloudAkskModalValidate('ak')}/>
                <div class='error-msg'>{this.akskModalErrMsg.ak}</div>
              </a-form-model-item>
              <a-form-model-item label="AccessKey Secret">
                <a-input v-model={this.akskForm.sk}
                         onBlur={() => this.handleBlurCloudAkskModalValidate('sk')}/>
                <div class='error-msg'>{this.akskModalErrMsg.sk}</div>
              </a-form-model-item>
            </a-form-model>
            <div class='footer'>
              <a-button type="primary" onclick={() => handleAuthorize()}>授权访问</a-button>
              {this.userInfo.aliyunAkSkSet ? <a-button onclick={() => handleRemoveAuthorize()}
                                                       style={{ marginRight: '16px' }}>解除访问权限
              </a-button> : null}
              <a-button onClick={() => hideModal()}>
                取消
              </a-button>
            </div>
          </div>
        )
      });

      const hideModal = () => {
        this.akskForm = {
          ak: '',
          sk: ''
        };
        this.akskModalErrMsg = {
          ak: '',
          sk: ''
        };
        modal.destroy();
      };
    }
  }
};

export default setCloudAKSKMixin;
